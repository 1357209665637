.package-details {

    .modal-header > header {
        > .actions {
            position: absolute;
            right: 0;
            top: calc(50% - #{math.div($indirect-button-heights, 2)});

            .package-button + .dropdown {
                margin-left: box-model(m);
            }
        }
    }
}
