.contents-detail {
    > header {
        margin-bottom: box-model(xl);

        h1 {
            @include ellipsis(true);
        }
    }

    .contents {
        display: flex;

        .content {
            width: grid-col-width(8);
        }

        .video-preview, .document-preview {
            margin-left: box-model(xl);
            width: grid-col-width(6);

            .media-player {
                .smvp-video-player {
                    border-radius: 5px;
                }
            }
        }

        .video-processing {
            position: relative;
            max-height: 100%;
            width: 100%;
            background-color: #000;
            border-radius: 5px;
            padding-bottom: 56.25%;

            .badge {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%);
                color: color(base-300);
                background-color: color(base-700);
            }
        }

        .smvp-video-player {
            border: 1px solid color(base-300);
        }
    }
}
