@import "vue-multiselect/dist/vue-multiselect.min";

/**
 * Vue Multiselect
 * https://vue-multiselect.js.org/
 */
.multiselect.form-control {
    padding: 0;
    height: auto;
    min-height: 0;
    border: 0;

    &.multiselect--active {
        .toggler {
            transform: rotate(180deg);
        }

        .multiselect__input {
            border-radius: $base-border-radius;
        }
    }

    &.multiselect--disabled {
        opacity: 1;
        cursor: not-allowed !important;

        .toggler {
            color: color(base-300);
        }
        .multiselect__tags {
            background-color: color(base-100);
            border-color: color(base-300);
            color: color(base-500);
        }
    }

    &.has-warning {
        .multiselect__tags {
            border-color: color(warning-300);
        }
    }

    &.has-error {
        .multiselect__tags {
            border-color: color(error-300);
        }
    }

    .remove,
    .toggler {
        position: absolute;
        top: 0;
        right: 0;
        height: $input-height;
        width: $input-height;
        text-align: center;
        line-height: $input-height; // no mixin needed here. icon's specific.
        z-index: 1;
    }

    .remove {
        appearance: none;
        right: $input-height;
        width: box-model(l);
        height: $input-height - 2px; // 2px is the border width
        top: 1px; // 1px is the border width
        cursor: pointer;
        color: color(base-700);
        background-color: color(base-000);
        border: 1px solid transparent;
        border-left: 0;
        border-right: 0;

        i {
            display: block;
        }
    }

    .toggler {
        .ic-chevron-down {
            line-height: $input-height;
            width: 100%;
            display: block;
        }
    }

    .multiselect__tags {
        padding: box-model(0, xl, 0, 0);
        border-color: color(base-500);
        min-height: $input-height;
        border-radius: $base-border-radius;
        line-height: 1;
        background-color: color(base-000);

        &:focus-within {
            border-color: color(primary-300);
            box-shadow: 0 0 0 1px color(primary-300);
            line-height: 1;
        }
    }

    .multiselect__input,
    .multiselect__placeholder,
    .multiselect__single {
        @include font-size(base);
        @include line-height(s);
        @include ellipsis;
        padding: box-model(xxs, s);
        border: 0;
        margin: 0;
        min-height: 0;
        background-color: transparent;
        display: block;

        &:focus {
            box-shadow: none !important;
        }
    }

    .multiselect__placeholder {
        color: color(base-500);
    }

    i {
        &.multiselect-left-icon{
            margin-right: box-model(xs);
        }
    }

    .max-elements {
        @include line-height(base);
        background-color: color(warning-100);
        white-space: normal;
        margin: box-model(-xs, -s);
        padding: box-model(xs, s);
        display: block;
    }
}

.multiselect__content-wrapper {
    background-color: color(base-000);
    border: 1px solid color(base-500);
    border-radius: $base-border-radius;
    box-sizing: border-box;
    box-shadow: $base-box-shadow;
    padding: 0;
    margin-top: box-model(xs);
    z-index: z-index(dropdowns);

    &:has(.multiselect__option--group) {
        .multiselect__option {
            padding-left: box-model(l);

            &.multiselect__option--group {
                padding-left: box-model(xs);
            }
        }
    }
}

.multiselect__option {
    @include line-height(document);
    padding: box-model(xs, s);
    min-height: $input-height;

    &::after {
        display: none;
    }
}

.multiselect__option--group {
    @include font-weight(semibold);
    background-color: transparent;
    color: color(base-700);
}

.multiselect__option--highlight {
    color: color(base-700);
    background-color: color(primary-100);
}

.multiselect__option--selected,
.multiselect__option--selected.multiselect__option--highlight {
    @include font-weight(normal);
    color: color(base-000);
    background-color: color(primary-300);
}

.multiselect__tags-wrap {
    display: block;
    line-height: 0;
    font-size: 0;
    max-width: calc(100% - box-model(l));
}

.multiselect__tag {
    @include font-size(s);
    @include line-height(xxxs);
    margin: box-model(xxs);
    background-color: color(base-300);
    color: color(base-700);
    max-width: calc(100% - #{box-model(xs)});

    button.btn-icon.single {
        width: auto;

        i {
            @include font-size(s);
            @include line-height(document);
        }
    }
}

.multiselect__tag-icon {
    &::after {
        color: color(base-700);
    }

    &:focus,
    &:hover {
        background-color: color(base-300);

        &::after {
            color: color(error-300);
        }
    }
}

.multiselect--above .multiselect__content-wrapper {
    margin-bottom: box-model(xs);
    border: 1px solid color(base-500);
    border-radius: $base-border-radius;
}

.strings-array-input {
    .multiselect__content-wrapper {
        display: none;
    }
}

.multiselect__spinner {
    border-radius: $small-border-radius;
    height: box-model(xl);
    width: box-model(xl);
    background-color: transparent;
    top: 0;
    right: 0;

    &::after,
    &::before {
        margin: #{box-model(-xs) - 2px} 0 0 #{box-model(-xs) - 2px};
        border-top-color: color(primary-300);
    }
}
