// Forms generics
// --------------

::placeholder {
    @include font-size(base);
}

::-ms-clear {
    display: none;
}

*[disabled] {
    cursor: not-allowed !important;

    &.working {
        cursor: wait !important;
    }
}

input[type="search" i]::-webkit-search-cancel-button {
    display: none;
}

// Wrappers and surroundings
// -------------------------

fieldset {
    border: 0;
    padding: 0;
    margin: 0 0 box-model(m);
    min-width: 0;

    &:last-child {
        margin-bottom: 0;
    }

    legend {
        @extend %h3;
        display: block;
        width: 100%;
    }
}

.search-group {
    margin-bottom: box-model(l);

    .btn.primary {
        margin-left: box-model(l);
    }
}

.search-bar {
    &.form-group {
        .search-input-wrapper {
            display: block;
        }
    }

    .search-input-wrapper {
        display: inline-block;
        position: relative;

        & + .btn {
            margin-left: box-model(xs);
        }
    }

    $search-icon-size: 32px;

    i {
        @include line-height(xxm);
        color: color(base-700);
        height: $search-icon-size;
        position: absolute;
        right: 0;
        text-align: center;
        top: 0;
        width: $search-icon-size;

        &.ic-x {
            cursor: pointer;
        }
    }

    input[type="search"], input[type="text"] {
        &:not(.form-control) {
            @extend %form-control;
            min-width: $search-input-min-width;
            padding-right: $search-icon-size;
        }
    }

    .no-results, .empty {
        margin-top: box-model(m);
    }
}

// Form groups
// -----------

.grouped-controls, .workspace-category {
    margin-bottom: box-model(l);

    &:last-child {
        margin-bottom: 0;
    }

    .form-groups > .form-group {
        margin-bottom: 0;
    }
}

.grouped-features {
    border: 1px solid color(base-500);
    border-radius: box-model(xxs);
    padding: box-model(m);
    margin-top: box-model(m);
    box-shadow: box-model(0, xxxs, xxxs) colorAlpha(base-700, 0.04);

    > .external-picker {
        margin-bottom: 0;
    }
}

.related-controls {
    margin-bottom: box-model(l);

    .form-group {
        margin-bottom: box-model(xs);
    }
}

.form-groups {
    display: flex;
    gap: box-model(m);

    > .form-group,
    > .external-picker {
        flex: 1 1 50%;
        margin: 0;
    }
}

%form-group {
    margin-bottom: box-model(l);

    &:last-child {
        margin-bottom: 0;
    }

    &:has(.multiselect--disabled) {
        cursor: not-allowed;
    }

    &.small {
        width: box-model(xs) * 12;
    }

    &.small-input {
        &.with-unit {
            input {
                flex: 0 1;
            }
        }

        input {
            width: box-model(xs) * 12;
        }
    }

    &.inline {
        display:inline-block;
        margin-right: box-model(m);

        label {
            margin: 0;
        }
    }

    > .actions {
        @include font-size(s);
        @include line-height(xxs);
        float: right;
    }

    label + .alert {
        margin-bottom: box-model(s);
    }
}

.form-group {
    @extend %form-group;

    &.form-group.inline {
        margin-left: box-model(s);
        margin-top: -1px; // border inline correction
    }
}

.form-removables {
    display: flex;
    margin-bottom: box-model(m);

    > *:first-child {
        flex: 1;
    }

    > .btn {
        max-height: $button-max-height;
        margin-right: 0;
        margin-left: box-model(xs);
        align-self: center;
    }
}

.form-check {
    position: relative;
    display: block;
    padding-left: $form-check-input-gutter;
}

.form-group.with-actions {
    display: flex;
    gap: box-model(xs);
    flex-wrap: wrap;

    label, .control-hint {
        flex: 0 1 100%;
        margin: 0;
    }

    .input-wrapper {
        flex-grow: 1;
        padding: box-model(xs, xs, xs, m);
        border: 1px solid color(primary-300);
        border-radius: $large-border-radius;
        box-shadow: box-model(-xxxs, xxs, s) colorAlpha(base-700, 0.05);
    }

    input {
        padding: 0;

        &, &:focus {
            border: 0;
            box-shadow: none;
        }
    }

    .form-control {
        flex: 1;

        + .btn {
            margin-left: 0;
        }
    }
}

.meta-form {
    margin-bottom: box-model(l);

    fieldset:has(+ .expandable) {
        margin-bottom: box-model(l);
    }
}

// Form labels
// -----------

label {
    @include font-size(s);
    @include font-weight(semibold);
    @include line-height(xxs);
    margin-bottom: box-model(xs);
    display: block;

    &[for] {
        cursor: pointer;
    }

    &[for]:has(+ * [disabled]) {
        cursor: not-allowed;
    }

    &.radio, &.indented-radio, &.checkbox {
        @include font-weight(normal);
        @include font-size(base);
        cursor: pointer;
        margin-bottom: 0;

        &:has(+ .radio),
        &:has(+ .indented-radio),
        &:has(+ .checkbox) {
            margin-bottom: box-model(xs);
        }

        input {
            margin-right: box-model(xs);
            display: inline-block;
            vertical-align: middle;
            margin-top: box-model(-xxxs) - 1px;

            &[disabled] + span {
                color: color(base-500);
            }
        }
    }

    &.checkbox {
        display: inline-block;

        &.itemised {
            @include font-weight(semibold);

            border: 1px solid color(base-500);
            color: color(base-500);
            padding: box-model(xxs, s);
            border-radius: box-model(m);
            margin-right: box-model(xs);
            margin-bottom: box-model(xs);
            transition: background-color .2s ease-in-out;

            &.selected {
                color: color(primary-300);
                border-color: color(primary-300);
            }

            &.disabled {
                cursor: not-allowed;
                border-color: color(base-300);
            }

            &:hover:not(.disabled) {
                background-color: color(primary-100);
            }

            input:focus {
                outline: 0;
            }
        }

        & + .control-hint {
            margin-top: box-model(-xxs);
        }
    }

    &.has-warning {
        color: color(warning-300);
    }

    &.has-error {
        color: color(error-300);
    }

    &.with-hint {
        margin-bottom: box-model(s);

        .text {
            @include font-weight(semibold);
        }

        .hint {
            @include font-size(s);

            color: color(base-500);
        }
    }

    .tip {
        vertical-align: middle;
        margin-left: box-model(xxs);
        margin-top: -3px; // icon's specific, no need for variable
        display: inline-block;
    }
}

.form-check-label {
    @include font-weight(normal);
    cursor: pointer;
    margin-bottom: 0; // Override default `<label>` bottom margin
}

// Form controls
// -------------

textarea {
    resize: vertical;
    min-height: $input-height;
}

%form-control {
    @include font-size(base);
    @include line-height(s);
    @include primary-font;

    box-sizing: border-box;
    box-shadow: none;
    border: 1px solid color(base-500);
    display: block;
    width: 100%;
    min-height: $input-height;
    padding: box-model(xxs, s);
    color: color(base-700);
    background-color: color(base-000);
    background-clip: padding-box;
    border-radius: $base-border-radius;
    transition: $base-border-transition;

    &:hover {
        border-color: color(base-700);
    }

    &:focus, &:focus-within, &:focus-within:hover {
        outline: none;
        border-color: color(primary-300);
        box-shadow: 0 0 0 1px color(primary-300);
    }

    // Placeholder
    &::placeholder {
        color: color(base-500);
        // Override Firefox's unusual default opacity
        opacity: 1;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none;
        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }

    &[type=number] {
        appearance: textfield;
        -moz-appearance: textfield; /* Firefox */
    }

    &[type="file"] {
        padding: 1px;
        height: $input-height;
        cursor: pointer;
    }

    &[disabled], .disabled {
        color: color(base-500);
    }

    // Datepickers
    &.date-picker {
        padding: 0;
        border: 0;
    }

    // Disabled and read-only inputs
    //
    // HTML5 says that controls under a fieldset > legend:first-child won't be
    // disabled if the fieldset is disabled. Due to implementation difficulty, we
    // don't honor that edge case; we style them as disabled anyway.
    &:disabled,
    &[readonly] {
        background-color: color(base-100);
        border-color: transparent;
        // iOS fix for unreadable disabled content
        opacity: 1;
    }

    & + .btn {
        margin-left: box-model(xs);
    }

    // Validations
    // -----------

    &.has-warning {
        border-color: color(warning-300);

        &:focus {
            box-shadow: 0 0 0 1px color(warning-300);
        }
    }

    &.error, &.has-error {
        border-color: color(error-300);

        &:focus {
            box-shadow: 0 0 0 1px color(error-300);
        }
    }

    &.input-small {
        display: inline-block;
        width: 80px;
    }

    &.input-medium {
        display: inline-block;
        width: 120px;
    }

    &.choose {
        @include line-height(s);
        @include icon(ic-chevron-down);

        color: color(base-500);
        cursor: pointer;
        overflow: hidden;
        transition: $base-border-transition, $base-text-transition;

        &:hover {
            color: color(base-700);
        }

        &::before {
            @include iconize;

            position: absolute;
            right: box-model(m);
            margin-top: box-model(xxs);
        }
    }

    + .control-hint {
        margin-top: box-model(xs);
    }
}

.form-control {
    @extend %form-control;
}

.form-control-inline {
    @extend %form-control;

    display: inline-block;
}

select.form-control,
select.form-control-inline {
    height: $input-height;

    &:focus::-ms-value {
        // Suppress the nested default white text on blue background highlight given to
        // the selected option text when the (still closed) <select> receives focus
        // in IE and (under certain conditions) Edge, as it looks bad and cannot be made to
        // match the appearance of the native widget.
        color: color(base-700);
        background-color: color(base-000);
    }

    &[size],
    &[multiple] {
        height: auto;
    }
}

select {
    appearance: none;
    background: image-url('chevron-down.svg') no-repeat 98%;
    background-size: box-model(m);
    padding-right: box-model(l);

    &:not(.form-control) {
        padding-right: box-model(l);
    }
}

textarea.form-control {
    height: auto;
}

.character-count {
    @include font-weight(normal);
    @include font-size(s);
    @include line-height(document);

    text-align: end;
    margin-top: box-model(xs);
    color: color(base-500);
}

// Checkboxes and radios
// ---------------------

input[type="checkbox"], input[type="radio"] {
    cursor: pointer;

    &.form-control {
        display: inline-block;
        min-width: 0;
        min-height: 0;
        height: auto;
        width: auto;
        vertical-align: middle;
        margin-top: -2px; // Inline offset correction
    }

    &:focus {
        outline: 2px solid color(primary-300);
        outline-offset: 2px;
        outline-color: color(primary-300);
        outline-style: auto;
        box-shadow: none;
    }

    &.form-check-input {
        position: absolute;
        margin-top: $form-check-input-margin-y;
        margin-left: $form-check-input-gutter * -1;

        // Use disabled attribute instead of :disabled pseudo-class
        // Workaround for: https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/11295231
        &[disabled] ~ .form-check-label {
            color: color(base-500);
        }
    }
}

.with-autocomplete {
    .input-wrapper {
        position: relative;
    }

    .ghost {
        @include ellipsis;
        max-width: calc(100% - box-model(l));
        position: absolute;
        margin-top: /* bottom padding */ (box-model(xxs) + line-height-original(base)) * -1;
        margin-left: box-model(s) + 1; // +1 because of the border
        transition: opacity .2s ease-in-out;
        opacity: 0;
        z-index: -1;

        &.in {
            opacity: .2;
        }
    }

    input {
        background-color: transparent;
    }

    .dropdown {
        display: inline;
    }
}

.feature-dependant {
    margin-top: box-model(m);
    padding-left: box-model(xxl);

    .feature-dependant {
        margin-left: box-model(xl);

        &::before {
            display: none;
        }
    }

    &.form-group, .form-group, .grouped-controls {
        margin-bottom: box-model(m);

        &::before {
            @include iconify('ic-corner-down-right', false);
            margin: box-model(xxs, 0, 0, -l);
            display: block;
            float: left;
        }
    }

    .grouped-controls {
        .form-groups {
            margin-left: 0;
        }

        .form-group {
            margin-bottom: 0;
            padding-left: 0;

            &::before {
                display: none;
            }
        }
    }

    .form-group:has(+ .control-hint) {
        margin-bottom: 0;
    }

    .control-hint {
        margin-top: 0;
    }
}

// Hints and tips
.control-hint {
    @include font-size(s);
    @include line-height(xxs);
    color: color(base-500);

    &:has(+ .externals-list),
    &:has(+ .form-control) {
        margin-bottom: box-model(xs);
    }
}

.control-label + .control-hint {
    margin-top: 0;
    margin-bottom: box-model(xs);
}

// Combo controls (i.e. slider with %)
// ---------------
.control-group {
    .controls {
        line-height: $input-height;
        display: flex;

        input:first-child {
            flex: 1;
        }
    }
}

.input-wrapper {
    &.with-content, &.with-unit {
        display: flex;

        input {
            flex: 1 1;
        }

        .btn {
            overflow: visible;
        }
    }

    &.with-unit {
        &:focus-within {
            .unit {
                box-shadow: 0 0 0 1px color(primary-300);
                border-color: color(primary-300);
            }
        }

        input {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    input {
        &.with-icon {
            padding-left: box-model(s) * 3;
        }
    }

    i {
        position: absolute;
        padding: box-model(xs, 0, 0, s);
    }

    .unit {
        @include line-height(xxm);
        border: 1px solid color(base-500);
        border-left: 0;
        border-top-right-radius: $base-border-radius;
        border-bottom-right-radius: $base-border-radius;
        color: color(base-600);
        background-color: color(base-300);
        padding: box-model(0, xs);
    }
}

// Copiable prefixed input
.copiable-prefixed {

    position: relative;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    margin-bottom: box-model(xs);

    .prefixing-dropdown, .prefixing-label {
        background-color: color(base-300);
        display: inline-flex;
        flex-grow: 0;
        width: box-model(h);
        margin-right: 0px;
        padding-left: box-model(xs);
        padding-top: box-model(xxs);
        border: 1px solid;
        border-right: none;
        border-color: color(base-500);
        border-radius: box-model(xxs) 0px 0px box-model(xxs);
    }

    .prefixing-dropdown {
        .activator span {
            justify-content: space-around;
        }

        .menu {
            transform: translate(box-model(l), box-model(s) * 1.5);
        }
    }
    .form-group {
        display: inline-flex;
        flex-grow: 1;
        margin-left: 0px;

        & > .input-wrapper { flex-grow: 1; }

        .form-control[readonly] {
            border-color: color(base-500);
            border-radius: 0px box-model(xxs) box-model(xxs) 0px;
            background-color: color(base-100);
        }
    }
}

// Suffixed input

.suffixed-input {
    position: relative;
    margin-bottom: box-model(xs);

    .input-wrapper {
        display: inline-flex;
        flex-direction: row;
        flex-grow: 1;
        input {
            flex: unset;
            border: unset;
            border-radius: unset;
            border-radius: box-model(xxs) 0px 0px box-model(xxs);

            &:hover, &:focus {
                border-radius: unset;
                border: unset;
            }
        }
        border: 1px solid color(base-500);
        border-radius: box-model(xxs);
        &:hover {
            border-color: color(base-700);
        }

        &:focus, &:focus-within, &:focus-within:hover {
            outline: none;
            border-color: color(primary-300);
            box-shadow: 0 0 0 1px color(primary-300);
        }

    }

    &.has-warning {
        .input-wrapper {
            border-color: color(warning-300);
            box-shadow: none;

            &:hover {
                border-color: color(warning-300);
                box-shadow: none;
            }

            &:focus, &:focus-within, &:focus-within:hover {
                border-color: color(warning-300);
                box-shadow: 0 0 0 1px color(warning-300);
            }
        }
    }

    &.error, &.has-error {
        .input-wrapper {
            border-color: color(error-300);
            box-shadow: none;

            &:hover {
                border-color: color(error-300);
                box-shadow: none;
            }

            &:focus, &:focus-within, &:focus-within:hover {
                border-color: color(error-300);
                box-shadow: 0 0 0 1px color(error-300);
            }
        }
    }

    .form-group {
        flex-grow: 1;
        margin-right: 0px;

        .form-control[readonly] {
            border: unset;
            background-color: color(base-100);
        }
    }

    .suffixing-label {
        background-color: color(base-300);
        display: inline-flex;
        width: min-content;
        margin-left: 0px;
        padding-left: box-model(xs);
        padding-right: box-model(xs);
        padding-top: box-model(xxs);
        border: none;
        border-left: 1px solid color(base-500);
        border-radius: 0px box-model(xxs) box-model(xxs) 0px;
    }
}

// Duration input

.duration-input {
    margin-bottom: box-model(s);

    .input-fields {
        display: flex;

        &:last-child {
            padding-right: 0;
        }

        .suffixed-input {
            margin-bottom: 0;
            padding-right: box-model(m);

            .form-group {
                margin-bottom: 0;
            }
        }
    }
}

// Select 2 custom
// ---------------

.select2-container {
    margin-bottom: box-model(xs);
    display: block;

    &.select2-container-disabled {
        .select2-choice {
            cursor: not-allowed;
            background-color: color(base-100);
            border-color: color(base-100);
        }
    }

    .select2-choice {
        @include line-height(s);

        box-sizing: border-box;
        height: $input-height;
        padding: box-model(xxs, s);
        color: color(base-500);
        border-color: color(base-500);
        border-radius: $base-border-radius;
        background-color: color(base-000);
        background-image: none;
        transition: $base-border-transition;

        &:hover {
            color: color(base-700);
            border-color: color(base-700);
        }

        .select2-arrow {
            @include line-height(xm);
            @include icon(ic-chevron-down);

            width: 32px;
            border-left: 0;
            border-radius: 0;
            background: transparent;
            background-image: none;

            &::before {
                @include iconize;

                position: absolute;
                top: 50%;
                left: 50%;
                margin-left: -7px; // Specific for the icon, no need for variables
                margin-top: -7px;
            }

            b {
                display: none;
            }
        }
    }

    .select2-chosen {
        color: color(base-700);
    }

}

.select2-drop {
    background-color: color(base-000);
    color: color(base-700);
}

.select2-container-active {
    .select2-choice,
    .select2-choices {
        box-shadow: none;
    }
}

.select2-dropdown-open {
    .select2-choice {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-color: color(primary-300);
    }
}

.select2-drop-active {
    border-color: color(primary-300);
}

.select2-search {
    padding-left: box-model(xs);
    padding-right: box-model(xs);

    input {
        border-radius: $base-border-radius;
        height: $input-height;
        margin-bottom: box-model(xs);
    }
}

.select2-results {
    padding: 0;
    margin: 0;

    .select2-highlighted {
        background-color: color(primary-300);
    }

    .select2-result-label {
        @include line-height(document);
        padding: box-model(xs, m);
    }
}

.select2-no-results {
    border-radius: 0 0 $base-border-radius $base-border-radius;
    padding: box-model(xs, m);
    background-color: transparent;
}

p.warning {
    @include font-size(s);
    @include line-height(s);
    color: color(warning-300);
    margin-top: box-model(xs);
    margin-bottom: 0;
}

span.error-msg {
    display: block;
}

.error-msg, p.error {
    @include font-size(s);
    @include line-height(s);
    color: color(error-300);
    margin-top: box-model(xs);
    margin-bottom: 0;
}

label.toggler {
    @include font-size(base);
    @include font-weight(normal);
    cursor: pointer;
    display: inline-block;
    padding-left: box-model(xxl);

    &:focus-within {
        > span {
            box-shadow: 0 0 0 2px color(primary-300);
        }
    }

    .no-main-label & {
        @include font-size(s);
        @include font-weight(semibold);
    }

    &.disabled {
        cursor: not-allowed !important;
        color: color(base-500);

        input:checked + span.switch {
            opacity: .6;
        }

        i.ic-info-fill {
            color: color(base-700);
        }
    }

    > span {
        display: block;
        float: left;
        width: box-model(xl);
        height: box-model(m);
        position: relative;
        overflow: hidden;
        border-radius: box-model(xs);
        background-color: color(base-300);

        margin: box-model(1px, xs, 0, -xxl);
    }

    span.switch, .switch-on, .switch-off {
        display: block;
        height: box-model(m);
        width: box-model(m);
        border-radius: box-model(xs);
        background-color: color(base-500);
        position: absolute;
        top: 0;
        left: 0;
        transition: $togglers-transition;
    }

    input {
        position: absolute;
        left: - box-model(m);
    }

    input[type="checkbox"]:focus, input[type="radio"]:focus {
        outline: none;
    }

    input[type="checkbox"]:focus, input[type="radio"]:focus {
        outline: none;
    }

    input:checked + span.switch, .switch-off {
        background-color: color(primary-300);
        left: calc(100% - #{box-model(m)});
    }

    & + .control-hint {
        margin-top: box-model(-xxs);
        margin-left: box-model(xxl);
    }

    .inline-hint {
        color: color(base-500);
        margin-left: box-model(xxs);
    }
}

.form-multiselect {
    margin-bottom: box-model(xs);
}

.radiogroup-inline {
    label {
        display: inline-block;
        margin-right: box-model(m);
    }
}

.radiogroup {
    .radio-label {
        display: inline-block;
        vertical-align: middle;
    }
}

.checkboxgroup {
    &.checkboxgroup-inline {
        .form-group {
            display: inline-block;
        }
    }

    > label {
        cursor: default;
    }

    .form-group {
        margin-bottom: 0;

        label:not(.itemised) {
            margin-right: box-model(m);
        }
    }

    .toggler {
        margin-right: box-model(s);
        min-width: box-model(xs) * 10;
    }

    p.error, p.warning {
        margin-top: 0;
    }
}

.dynamic-fields,
.event-details-section {
    background-color: color(base-000);
    border-radius: $large-border-radius;

    .drag-handler {
        @include font-size(document);
        @include line-height(xxm);
        @include font-weight(normal);

        opacity: .5;
        margin-top: box-model(-xxxs);
        height: box-model(xl);
        width: box-model(xl);
        flex: 0 0 box-model(xl);
        text-align: center;
    }
}

.dynamic-fields {
    header .headers,
    footer,
    .field-item {
        padding: box-model(xs);
        border: 1px solid color(base-300);
        border-top-color: transparent;
    }

    header .headers {
        padding-left: 0;
        border-radius: $large-border-radius $large-border-radius 0px 0px;

        &:first-child {
            border-top-color: color(base-300);
        }
    }

    header .headers,
    .field-item {
        display: flex;
        align-items: center;
    }

    header .headers {
        @include font-weight(semibold);
    }

    footer {
        border-radius: 0px 0px $large-border-radius $large-border-radius;

        .dropdown + .dropdown,
        .dropdown + .btn,
        .btn + .dropdown {
            margin-left: box-model(xs);
        }
    }

    .field-item {
        @include line-height(xxm);
        padding-left: 0;
        cursor: move;

        &:hover {
            &:not(.sortable-chosen) {
                border: 1px solid color(primary-300);
                background: color(primary-100);
            }

            .edit, .remove {
                display: inline-block;
            }
        }

        &.type-text, &type-legal-document, &.type-sessions {
            .field-name {
                @include ellipsis;
            }
        }

        i {
            vertical-align: middle;
        }
    }

    .field-name {
        flex: 0 0 box-model(xs) * 39;
        min-width: 0;
        max-width: box-model(xs) * 39;

        .badge:first-child {
            margin-left: 0;
        }
    }

    .optionality, .editability {
        text-align: center;

        .checkbox {
            margin-bottom: 0;
        }

        i.ic-info-fill {
            vertical-align: middle;
            margin-left: box-model(xs);
            cursor: pointer;
        }
    }

    .optionality,
    .editability,
    .headers .actions,
    .field-list .actions {
        flex: 1 1 20%;
    }

    .actions {
        text-align: right;
    }

    .edit, .remove {
        display: none;
    }

    .sortable-ghost {
        opacity: 0.5;
    }

    .button-and-dropdown {
        @include line-height(xs);

        padding: math.div(box-model(s), 2) box-model(s);
    }

    .dropdown .menu.dropdown-vue {
        margin-left: 0;
    }
}

.bulk-editor {
    .alert, .search-bar {
        margin-bottom: box-model(m);
    }

    .editing-group {
        margin-bottom: box-model(m);

        .form-group {
            margin-bottom: 0;
        }

        .feature-dependant {
            margin-top: box-model(xs);
        }
    }
}

.choice-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: box-model(m);
    align-items: stretch;

    &.wide {
        .choice {
            /// Unfortunately flex-basis does not account for the flex gap
            /// therefore we have to do it manually with the `calc` minus
            /// the distributed gap (gap size, 16px, times number of gaps (1) divided 2 items: (16px * 1) / 2 = 8px)
            flex: 0 1 calc(50% - #{box-model(xs)});
        }
    }

    .choice {
        /// Same applies here:
        /// 16px times 2 (32px), divided 3 items: (16px * 2) / 3 = 10.6666px)
        flex: 0 1 calc((100% / 3) - #{math.div(box-model(m) * 2, 3)});
        position: relative;
        cursor: pointer;
        margin: 0;

        &.checked {
            .form-control {
                border-color: color(primary-300);
                background-color: color(primary-050);
                color: color(primary-300);
            }
        }

        &:hover {
            .form-control {
                border-color: color(primary-300);
                color: color(primary-300);
            }
        }
    }

    .form-control {
        padding: box-model(m, l);
        height: 100%;
        display: flex;
        align-items: center;
    }

    i {
        @include icon-size(xs);
        vertical-align: middle;

        &:last-of-type {
            margin-right: box-model(xs);
        }

        & + i {
            margin-left: box-model(xxs);
        }
    }

    .text {
        @include line-height(base);
        vertical-align: middle;
    }

    input {
        height: 1px;
        width: 1px;
        opacity: 0;
        position: absolute;
        top: -1px;
        left: -1px;
    }
}

.modal-form {
    .meta-form {
        margin-bottom: 0;
    }

    .modal-body .loader {
        min-height: box-model(h);
    }
}

.nested-control {
    h5 {
        margin-bottom: box-model(m);
    }

    .wrapper {
        display: flex;
        gap: box-model(xs);

        .label {
            @include line-height(xxm);
            flex: 1 1 auto;
        }
    }
}
