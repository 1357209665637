.smart-table {
    .list-buttons {
        display: flex;
        margin-bottom: box-model(m);
        height: box-model(xl);
        white-space: nowrap;
        align-items: flex-end;

        .bulk-actions,
        .filter-wrapper {
            gap: box-model(m);
            display: flex;
        }

        .bulk-actions {
            flex: 1 1 100%;
            gap: box-model(xs);
        }

        .calls-to-action {
            order: 0;
            overflow: auto;
            flex: 1 1 auto;
        }

        .filter-wrapper {
            order: 10;
            align-items: flex-end;
        }
    }

    .list-filters {
        .multiselect {
            min-width: $dropdowns-min-width;
        }

        .form-group {
            display: flex;
            align-items: center;
            gap: box-model(s);

            label {
                margin: 0;
            }
        }
    }

    .item-list {
        display: flex;
        align-items: stretch;
        overflow-x: hidden;

        &.quick-editor-open {
            overflow-x: visible;
            .table-wrapper {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        &.animating {
            .table-wrapper {
                overflow: hidden;
            }
        }

        .list-quick-editor {
            flex: 0 0 auto;
        }

        .table-wrapper {
            flex: 1 1 auto;
        }
    }

    th {
        &.selector {
            width: box-model(m);
        }
    }

    .tag-list-display {
        display: flex;

        .badge {
            margin: 0 math.div(box-model(s), 2);
            display: flex;
            align-items: center;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .row-actions {
        text-align: right;

        > * {
            visibility: hidden;
        }
    }

    .actions {
        text-align: right;
        min-width: 80px;
    }

    tbody {
        tr {
            &:hover .row-actions > * {
                visibility: visible;
            }

            &[class$="cancelled"],
            &[class$="hidden"] {
                td:not(.row-actions):not(.status) {
                    > * {
                        opacity: .5;
                    }
                }
            }
        }

        a:not(.btn) {
            @include font-weight(semibold);
        }
    }

    .empty-items {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        padding: box-model(xxl);
    }
}
