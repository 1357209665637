.accordion {
    .accordion-item {
        border: 1px solid color(base-300);
        border-radius: $large-border-radius;
        box-sizing: border-box;
        margin-bottom: box-model(l);

        &.with-header-icon {
            > section {
                // accordion paddings + icon width
                padding-left: (box-model(xs)*3 + icon-size(s)) !important;
            }
        }

        &.open {
            &.not-empty:hover {
                border-color: color(primary-300);
            }

            box-shadow: $modal-box-shadow;
            border-color: color(primary-300);

            > header .opposite .toggler i {
                color: color(primary-300);
                transform: rotate(0deg);
            }

            section {
                padding: box-model(0, m, m);
                overflow: visible; //for the select inputs not to be hidden behind below elements
            }
        }

        &.not-empty:hover {
            box-shadow: $modal-box-shadow;
            border-color: color(base-700);
        }

        &:last-of-type {
            margin-bottom: 0;
        }

        > header {
            @include font-weight(semibold);
            @include font-size(base);
            @include line-height(base);

            padding: box-model(s, m);

            .opposite .toggler i {
                transform: rotate(-90deg);
                color: color(base-700);
            }

            .badge {
                margin-left: box-model(s);
                margin-right: box-model(s);
            }
        }

        > section {
            padding: box-model(0, m);
        }

        .header-icon {
            i {
                @include font-size(document);
                line-height: 1; // No need for mixin here since it's specific for this icon
                height: icon-size(s);
                width: icon-size(s);
                border-radius: $base-border-radius;

                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                float: left;
            }
        }

        .header-text {
            padding-left: box-model(xs);
            height: 4 * box-model(xs);
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            justify-content: space-evenly;

            .failure-reason {
                @include font-size(xs);
                @include font-weight(normal);
                line-height: font-size(s);
                color: color(error-300);
                margin-top: - box-model(xxs);
                height: box-model(s);
            }
        }
    }
}
