.registration {
    padding-bottom: box-model(xl);

    .showcase {
        position: relative;
        font-size: 0;
        padding: box-model(xl, 0, m);

        &::after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            right: 0;
            left: 0;
            top: 50%;
            background: linear-gradient(180deg, colorAlpha(base-000, 0) 0%, color(base-000) 88.54%);
        }

        img {
            overflow: hidden;
            box-shadow:
                0px -4px 16px -2px rgba(0, 0, 0, 0.08),
                0px -8px 92px -4px rgba(0, 0, 0, 0.05);

            border-radius: $large-border-radius * 2;
        }
    }

    .activation {
        text-align: center;

        .form-group {
            text-align: left;
        }

        input[type="text"] {
            width: box-model(h) * 5;
        }
    }

    .activation-form {
        @include grid(12);

        .form-group {
            @include cols(8, 3);
        }

        .registration-url-picker  {
            @include cols(6, 4);
        }
    }

    .event-details {
        .heading {
            display: flex;
            margin-bottom: box-model(m);

            h5 {
                flex: 1;
                align-self: center;
            }

            .actions {
                text-align: right;
            }
        }

        hr.separator {
            margin-bottom: box-model(m);
        }

        .in-nav {
            @include font-size(s);

            color: color(base-500);
            padding: box-model(xs);
            border-right: 1px solid color(base-300);
            vertical-align: middle;
        }

        .title-and-actions {
            border-bottom-color: color(base-000);
        }

        .event-details-section {
            &.collapsed {
                .title-and-actions {
                    border-bottom-color: color(base-300);
                }

                .title-and-actions, .input-wrapper {
                    border-radius: $large-border-radius;
                }

                .toggler {
                    transform: rotate(180deg);
                }

                .section-configuration {
                    overflow: hidden;
                    padding-top: 0;
                    padding-bottom: 0;
                    opacity: 0;
                    border-top-width: 0;
                    border-bottom-width: 0;
                    height: 0;
                }
            }

            span.title {
                > i {
                    vertical-align: middle;
                    margin-right: box-model(xs);
                }

                &.empty {
                    opacity: .6;
                }
            }
        }

        .section-configuration {
            border: 1px solid color(base-300);
            border-top: 0;
            border-radius: 0 0 $large-border-radius $large-border-radius;
            box-sizing: border-box;
            padding: box-model(s);
            margin-bottom: box-model(l);

            transition:
                opacity .2s linear,
                padding .2s linear,
                border-top-color 2s linear;
        }

        .more-settings {
            margin-top: box-model(m);

            i {
                margin-left: box-model(xs);
                vertical-align: middle;
                display: inline-block;
            }

            &.in {
                .settings-wrapper {
                    opacity: 1;
                    height: auto;
                    margin-bottom: box-model(m);
                }

                i {
                    transform: rotate(180deg);
                }
            }

            .settings-wrapper {
                opacity: 0;
                transition: opacity .2s linear;
                height: 0;
            }
        }

        .empty-state {
            text-align: center;
            padding: box-model(l) box-model(xxxl);
            border: 1px solid color(base-300);
            border-radius: $large-border-radius;
        }
    }

    .design {
        h5 {
            margin-bottom: box-model(l);
        }

        .banner-picker {
            width: 50%;
        }

        .design-preview {
            .buttons-toggler {
                margin-top: box-model(l);
            }

            svg {
                margin-top: box-model(l);
                background-color: light-color(base-000);
                box-shadow:
                    0px -4px 16px -2px rgba(0, 0, 0, 0.08),
                    0px -8px 92px -4px rgba(0, 0, 0, 0.05);

                border-radius: $large-border-radius * 2;

                img {
                    object-fit: cover;
                }
            }
        }
    }

    .hint-manage-pending {
        border-top: 1px solid color(base-300);
        padding-top: box-model(l);

        p {
            @include line-height(s);
        }
    }

    .form-fields {
        header.subpage-heading {
            @include clearfix;
            margin-bottom: box-model(m);
        }

        form {
            margin-bottom: box-model(m);
        }
    }

    .dynamic-fields {
        background-color: color(base-000);
        border-radius: $large-border-radius;

        &.collapsed {
            .title-and-actions, .input-wrapper {
                border-radius: $large-border-radius;
            }

            .toggler {
                transform: rotate(180deg);
            }

            header .headers,
            header .conditions-editor,
            .field-list,
            footer {
                overflow: hidden;
                padding-top: 0;
                padding-bottom: 0;
                max-height: 0;
                opacity: 0;
                border-top-width: 0;
                border-bottom-width: 0;
            }
        }

        header .headers,
        header .conditions-editor,
        .field-list,
        footer {
            transition:
                opacity .2s linear,
                padding .2s linear,
                max-height .2s linear,
                border-top-width .2s linear,
                border-bottom-width .2s linear;
        }

        header .headers {
            max-height: box-model(xxxl);
        }

        .field-list {
            max-height: box-model(xs) * 50;
            overflow: auto;
        }

        footer {
            max-height: box-model(xxl) + box-model(xxxs);

            i.ic-info-fill {
                display: inline-block;
                vertical-align: middle;
                margin: box-model(0, xs, -xxs);
            }
        }

        .add-session-registration {
            i.ic-info-fill {
                @include font-size(base);
                color: color(base-700);
            }
        }
    }

    .title-and-actions {
        border: 1px solid color(base-300);
        border-radius: $large-border-radius $large-border-radius 0px 0px;
        padding: box-model(s, xs, s, xl);
        transition: border-radius .2s linear;
        border-bottom-color: color(base-300);
        box-sizing: border-box;

        &.editing {
            padding: 0;
            border: 0;
        }

        &:not(.editing) {
            .actions {
                float: right;
                margin-top: box-model(-xs);
            }
        }

        .title {
            @include ellipsis;
            display: inline-block;
            max-width: 75%;
            vertical-align: middle;
            margin-top: box-model(-xxxs);
        }

        .description {
            display: block;
            margin: box-model(xs) 0;
        }

        .drag-handler {
            float: left;
            margin-left: box-model(-xl);
            cursor: row-resize;
        }

        .toggler, .dropdown {
            vertical-align: middle;
        }

        .toggler {
            @include font-size(xxm);
            @include line-height(xxm);

            height: box-model(xxl);
            width: box-model(xxl);
            display: inline-block;
            text-align: center;
            transition: transform .2s linear;

            i {
                line-height: box-model(xl); // icon specific
                vertical-align: middle;
            }
        }

        form {
            margin-bottom: 0;
        }

        .input-wrapper {
            border-radius: $large-border-radius $large-border-radius 0px 0px;
            padding-left: box-model(xl);

            input {
                @include font-weight(semibold);
                margin-right: box-model(xs);
            }
        }
        .conditions-editor {
            .input-wrapper {
                padding-left: 0;
            }
        }

        .help {
            border: 1px solid color(base-300);
            border-top: 0;
            border-bottom: 0;
            padding: box-model(s, xs, s, xl);
            margin-top: 0;
        }

        span.title {
            @include font-weight(semibold);
        }

        + .headers {
            border-radius: 0;
        }
    }

    .add-conditions {
        @include font-size(s);
        @include line-height(base);
        display: inline-block;
        vertical-align: middle;
        padding: box-model(0, m);
        border-right: 1px solid color(base-300);
        margin-right: box-model(xs);

        i {
            display: inline-block;
            color: color(base-700);
            margin-left: box-model(xs);
            vertical-align: middle;
            margin-top: box-model(-xxxs);
        }
    }

    .emails {
        .expandable.open {
            > section {
                max-height: fit-content;
                padding-top: 0;
            }
        }

        .email-description {
            margin-bottom: box-model(l);
        }

        .email-template {
            margin-bottom: box-model(m);
        }

        .email-template-picker {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        .btn-remove-target {
            color: color(error-300);
        }
    }

    .settings {
        h6 {
            color: color(base-700);
            margin-bottom: box-model(xs);
        }
    }
}
